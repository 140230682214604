.centerLoader {
    display: flex;
    padding: 2rem 0;
    justify-content: center;
}

.noRecordsFound {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 2rem 0;

    img {
        display: block;
        margin-bottom: 2rem;
    }

    h3 {
        font-size: 2.1rem;
        color: $primary_color;
        font-weight: $Montserrat;
        margin: 0;
    }
}