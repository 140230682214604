.formBox {
	margin:0;
	
	fieldset {
		position: relative;
		z-index: 1;
		margin-bottom: 30px;

		&:after {
			content: '';
			position: absolute;
			top: 100%;
			left: 0;
			width: 0;
			height: 2px;
			margin-top: -1px;
			background: $secondary_color;
			@include css3Transition(200ms);
		}
		.placeholder {
			position: absolute;
			top: 10px;
			left: 0;
			z-index: -1;
			font-size: 16px;
			color: #474747;
			@include css3Transition(200ms);

			@media (max-width: $desktopSmall) {
				font-size: 14px;
			}
		}

		.rightPlaceHolder {
			position: absolute;
			top: 15px;
			right: 10px;
			z-index: 2;
			font-size: 13px;
			// color: #474747;
			@include css3Transition(200ms);
		}
		.bottomPlaceHolder {
			position: absolute;
			top: 105%;
			left: 0;
			z-index: 2;
			font-size: 13px;
			// color: #474747;
			@include css3Transition(200ms);
		}
		
		.form-control {
			font-family: $Montserrat;
			font-size: 16px;
			color: #212121;
			border: none;
			height: 46px;
			outline: none;
			background: transparent;
			border-bottom: solid #7b7b7b 1px;
			@include css3BorderRadius(0);
			
			&:focus {
				border-bottom-color: #333;
			}
		}
		
		select.form-control {
			cursor:pointer;
		}
		textarea.form-control {
			height:100px;
			resize:none;
		}
		
		label.error {
			position:absolute;
			top:105%;
			right:0;
			color:red;
			display:none;
		}
		
		&.input--filled {
			&:after {
				width: 100%;
			}
			.form-control {
				border-bottom-color: #333;
			}
			.placeholder {
				font-size: 14px;
				top:-15px;
				color: $secondary_color;
			}
		}
		
		@media(max-width:$mobileBig) {
			margin-bottom:25px;
		}
	}
	
	.radioBtnBox {
		display:flex;
		flex-wrap:wrap;
		align-items:center;
		
		li {
			position:relative;
			z-index:1;
			display:block;
			font-size: 16px;
			margin-left:25px;
			
			&:first-child {margin-left:0;}
			
			label {
				display:flex;
				margin: 0;
				cursor:pointer;
				align-items:center;
			}
			
			input {
				position:absolute;
				top:0;
				left:0;
				width:0;
				height:0;
				opacity:0;
				z-index:-1;
				
				&:checked + .icon {
					border-color: $secondary_color;
					&:before {display:block;}
				}
			}

			.icon {
				position:relative;
				display:block;
				width:20px;
				height:20px;
				cursor:pointer;
				border:solid #ababab 1px;
				@include css3BorderRadius(50%);
				
				&:before {
					display:none;
					content:'';
					position:absolute;
					top:50%;
					left:50%;
					width:10px;
					height:10px;
					background: $secondary_color;
					@include css3BorderRadius(50%);
					@include css3Transform(translate(-50%, -50%));
				}
			}
			.txt {
				font-size:15px;
				color:#333;
				padding-left:7px;
				white-space: nowrap;
				width:calc(100% - 25px);
			}
		}
	}
	
	.checkboxBtnBox {
		display:flex;
		
		li {
			position:relative;
			z-index:1;
			display:block;
			margin-bottom:25px;
			
			&:last-child {margin-bottom:0;}
			
			label {
				display:flex;
				cursor:pointer;
				align-items:center;
				
				&.tnc {
					align-items:flex-start;
					.txt {
						width:calc(100% - 40px);
					}
				}
			}
			
			input {
				position:absolute;
				top:0;
				left:0;
				width:0;
				height:0;
				opacity:0;
				z-index:-1;
				
				&:checked + .icon {
					border-color: $secondary_color;

					&:before {display:block;}
					&:after {display:block;}
				}
			}
			.icon {
				position:relative;
				display:block;
				width:16px;
				height:16px;
				cursor:pointer;
				border:solid #ababab 1px;
				
				&:before {
					display:none;
					content:'';
					position:absolute;
					top:0;
					left:0;
					width:14px;
					height:14px;
					background: $secondary_color;
				}
				&:after {
					display:none;
					content:'';
					position:absolute;
					top:0;
					left:0;
					width:14px;
					height:14px;
					background: image('checked.svg') center no-repeat;
					background-size: 10px;
				}
			}
			.txt {
				font-size:18px;
				color:#333;
				padding-left:20px;
				width:calc(100% - 25px);
				
				@media(max-width:$tabletSmall) {
					font-size:16px;
					padding-left:15px;
				}
			}
		}
	}
	
	&.viewMode {
		fieldset {
			.placeholder {
				position:static;
				display:block;
				font-family:$Montserrat;
				color:#676767;
				margin-bottom:10px;
			}
			p {
				font-size:18px;
				color:#333;
				margin-bottom:0;
			}
		}
	}
}

label.error {
	font-size: 14px;
	color: red;
}

// Modified: Bootstrap Default From style
.form-control {
	font-family: $Montserrat;
	font-size: 15px;
	height: auto;
	padding: 10px;
	color: $headingColor;
	@include css3BorderRadius(0);
	@include css3BoxShadow(none !important);

	&.smPadding {
		font-size: 14px;
		padding: 4px;
	}
}

select.form-control.customDropdownIcon {
	background: image('svg/caret-down.svg') 96% center no-repeat;
    background-size: 10px;
    -webkit-appearance: none;
    appearance: none;
}

.styled__checkBox {
	position:relative;
	z-index:1;
	
	label {
		font-family: $Montserrat;
		display:flex;
		margin: 0;
		cursor:pointer;
		align-items:center;
		
		&.tnc {
			align-items:flex-start;
			
			.txt {
				width:calc(100% - 40px);
			}
		}
	}
	
	input {
		position:absolute;
		top:0;
		left:0;
		// width:0;
		// height:0;
		opacity:0;
		z-index:-1;
		
		&:checked + .icon {
			border-color: $secondary_color;

			&:before {display:block;}
		}
	}
	.icon {
		position: relative;
		display: block;
		width: 16px;
		height: 16px;
		cursor: pointer;
		border: solid #cdcdcd 1px;
		
		&:before {
			display: none;
			content: '';
			position: absolute;
			top: -1px;
			left: -1px;
			width: 16px;
			height: 16px;
			background: image('svg/tick-white.svg') center no-repeat $secondary_color;
			background-size: 12px;
			// @include css3Transform(translate(-50%, -50%));
		}
	}
	.txt {
		font-size:15px;
		color: #939393;
		padding-left:10px;
		width:calc(100% - 15px);

		a {
			font-family: $Montserrat;
			color: #939393;

			&:hover {
				color: #333333;
				text-decoration: underline;
			}
		}
		
		@media(max-width:$tabletSmall) {
			font-size:16px;
			padding-left:15px;
		}
	}
}

