@import 'abstracts/variables';
@import 'abstracts/mixin';
@import 'abstracts/functions';

@import 'vendor/bootoast';
@import 'vendor/datepicker';
@import 'vendor/mCustomScrollbar';
@import 'vendor/modal';
@import 'vendor/select2';
@import 'vendor/slick';
@import 'vendor/slick_theme';
@import 'vendor/typed';


@import "base/animations";
@import "base/base";
@import "base/fonts";
@import "base/helper";
@import "base/typography";
@import "base/utilities";


@import "components/alert";
@import "components/banners";
@import "components/breadcrumb";
@import "components/button";
@import "components/card";
@import "components/custom-dropdown";
@import "components/datepicker";
@import "components/form-radio";
@import "components/forms";
@import "components/headings";
@import "components/loader";
@import "components/no-records";
@import "components/pagination";
@import "components/rating";
@import "components/section";
@import "components/table";
@import "components/tabs";
@import "components/range";
@import "components/_price-range";


@import "layouts/header";
@import "layouts/menu";
@import "layouts/grid";
@import "layouts/footer";


// Pages
@import "pages/home";
@import "pages/error-pages";
