@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
    font-family: 'Cormorant-Italic';
    src: font('CormorantItalic.eot');
    src: font('CormorantItalic.eot') format('embedded-opentype'),
         font('CormorantItalic.woff2') format('woff2'),
         font('CormorantItalic.woff') format('woff'),
         font('CormorantItalic.ttf') format('truetype'),
         font('CormorantItalic.svg#CormorantItalic') format('svg');
         font-weight: 400;
         font-style: italic;
}

@font-face {
    font-family: 'Cormorant-Medium';
    src: font('CormorantMedium.eot');
    src: font('CormorantMedium.eot') format('embedded-opentype'),
         font('CormorantMedium.woff2') format('woff2'),
         font('CormorantMedium.woff') format('woff'),
         font('CormorantMedium.ttf') format('truetype'),
         font('CormorantMedium.svg#CormorantMedium') format('svg');
         font-weight: 500;
         font-style: normal;
}

@font-face {
    font-family: 'Cormorant-SemiBold-Italic';
    src: font('CormorantSemiBoldItalic.eot');
    src: font('CormorantSemiBoldItalic.eot') format('embedded-opentype'),
         font('CormorantSemiBoldItalic.woff2') format('woff2'),
         font('CormorantSemiBoldItalic.woff') format('woff'),
         font('CormorantSemiBoldItalic.ttf') format('truetype'),
         font('CormorantSemiBoldItalic.svg#CormorantSemiBoldItalic') format('svg');
         font-weight: 600;
         font-style: italic;
}

@font-face {
    font-family: 'GreatVibes-Regular';
    src: font('GreatVibesRegular.eot');
    src: font('GreatVibesRegular.eot') format('embedded-opentype'),
         font('GreatVibesRegular.woff2') format('woff2'),
         font('GreatVibesRegular.woff') format('woff'),
         font('GreatVibesRegular.ttf') format('truetype'),
         font('GreatVibesRegular.svg#GreatVibesRegular') format('svg');
         font-weight: 400;
         font-style: normal;
}

@font-face {
    font-family: 'Nunito-SemiBold';
    src: font('NunitoSemiBold.eot');
    src: font('NunitoSemiBold.eot') format('embedded-opentype'),
         font('NunitoSemiBold.woff2') format('woff2'),
         font('NunitoSemiBold.woff') format('woff'),
         font('NunitoSemiBold.ttf') format('truetype'),
         font('NunitoSemiBold.svg#NunitoSemiBold') format('svg');
         font-weight: 600;
         font-style: normal;
}

@font-face {
    font-family: 'Nunito-ExtraBold';
    src: font('NunitoExtraBold.eot');
    src: font('NunitoExtraBold.eot') format('embedded-opentype'),
         font('NunitoExtraBold.woff2') format('woff2'),
         font('NunitoExtraBold.woff') format('woff'),
         font('NunitoExtraBold.ttf') format('truetype'),
         font('NunitoExtraBold.svg#NunitoExtraBold') format('svg');
         font-weight: 800;
         font-style: normal;
}

@font-face {
    font-family: 'proximanova-Light';
    src: font('proximanova-Light.woff2') format('woff2'),
         font('proximanova-Light.woff') format('woff'),
         font('proximanova-Light.otf') format('otf');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'proximanova-regular';
    src: font('proximanova-regular.woff2') format('woff2'),
         font('proximanova-regular.woff') format('woff'),
         font('proximanova-regular.ttf') format('ttf');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'proximanova-semibold';
    src: font('proximanova-semibold.eot');
    src: font('proximanova-semibold.eot') format('embedded-opentype'),
         font('proximanova-semibold.woff2') format('woff2'),
         font('proximanova-semibold.woff') format('woff'),
         font('proximanova-semibold.ttf') format('truetype'),
         font('proximanova-semibold.svg#proximanova-semibold') format('svg');
         font-weight: 600;
         font-style: normal;
}

@font-face {
    font-family: 'proximanova-bold';
    src: font('proximanova-bold.woff2') format('woff2'),
         font('proximanova-bold.woff') format('woff'),
         font('proximanova-bold.ttf') format('ttf');
    font-weight: 700;
    font-style: normal;
}