.section {
	padding: 80px 0;

	&.nopadding {
		padding: 0 !important;
	}
	&.nopadding--top {
		padding-top: 0 !important;
	}
	&.nopadding--bottom {
		padding-bottom: 0 !important;
	}

	// Sizes
	&.sm {
		padding: 40px 0;

		@media (max-width: $tablet) {
			padding: 30px 0;
		}
	}
	&.md {
		padding: 60px 0;

		@media (max-width: $desktop) {
			padding: 50px 0;
		}
		@media (max-width: $tablet) {
			padding: 40px 0;
		}
		@media (max-width: $mobileBig) {
			padding: 30px 0;
		}
	}
	&.xl {
		padding: 100px 0;

		@media (max-width: $desktop) {
			padding: 80px 0;
		}
		@media (max-width: $tablet) {
			padding: 40px 0;
		}
		@media (max-width: $mobileBig) {
			padding: 30px 0;
		}
	}
	&.xxl {
		padding: 120px 0;

		@media (max-width: $laptop) {
			padding: 100px 0;
		}
		@media (max-width: $desktop) {
			padding: 80px 0;
		}
		@media (max-width: $tablet) {
			padding: 60px 0;
		}
		@media (max-width: $mobileBig) {
			padding: 30px 0;
		}
	}

	&.travelPadding {
		padding: 150px 0 60px;
	}

	// Bg colors
	&.white {
		background: white;
	}
	&.dark {
		background: #353535;
	}
	&.primary {
		background: $primary_color;
	}
	&.secondary {
		background: $secondary_color;
	}
	&.light {
		background: $light_color;
	}
	&.light__grey {
		background: #dee2e2;
	}
	
	@media (max-width: $desktop) {
		padding: 60px 0;
	}
	@media (max-width: $tablet) {
		padding: 40px 0;
	}
	@media (max-width: $mobileBig) {
		padding: 30px 0;
	}
}
