.header {
	$self: &;

	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	z-index: 999;
	padding: 10px 0;
	transition: all 0.25s ease-out;

	@media (max-width: $desktopSmall - 1) {
		background-color: $color_white;
		box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);
	}

	.container {
		display: flex;
		align-items: center;
		justify-content: space-between;
		transition: height 0.25s ease-out;

		@media (max-width: $desktop_xl) {
			padding: 0 60px;
		}

		@media (max-width: $laptop - 1) {
			padding: 0 15px;
		}
	}

	&__logo {
		transition: all 0.25s ease-out;
		width: 126px;

		@media (max-width: $desktop_xl) {
			width: 80px;
		}

		@media (max-width: $desktopSmall - 1) {
			width: 60px;
		}

		a {

			img {
				width: 100%;
			}
		}
	}

	&__right {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.menuHandle {
			display: none;

			@media (max-width: $tablet) {
				display: block;
				position: relative;
				width: 33px;
				height: 26px;
			}
			
			a.menuBtn {
				position: absolute;
				top: 0;
				left: 0;
				width: 33px;
				height: 26px;
				cursor: pointer;
				z-index: 50;
				transition: all 20ms cubic-bezier(.4, 0, .2, 1);
				transition-delay: 100ms;

				span {
					position: absolute;
					top: 0;
					left: 0;
					display: block;
					width: 33px;
					height: 3px;
					z-index: 1;
					background: #000;
					transition: left 300ms cubic-bezier(.4, 0, .2, 1);

					&:last-child {
						top: 22px;
					}

					&.cross {
						top: 9px;
						left: -7px;
						z-index: 2;
						background: none;

						&:before,
						&:after {
							display: block;
							content: '';
							position: absolute;
							top: 50%;
							left: 0;
							width: 100%;
							height: 3px;
							background: #000;
							transition: all 350ms cubic-bezier(.4, 0, .2, 1);
						}
					}
				}

				&:hover {

					span.cross {
						left: 0;
					}
				}
			}

			&.active {

				a {

					span {

						&:first-child {
							opacity: 0;
						}

						&:last-child {
							opacity: 0;
						}

						&.cross {
							height: 26px;
							top: 0;
							left: 0;

							&:before {
								transform: rotate(-45deg);
							}

							&:after {
								transform: rotate(45deg);
							}
						}
					}
				}
			}
		}

		.headerLinks {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			white-space: nowrap;
			transition: all .25s cubic-bezier(.61,.03,.43,.9);
			margin-bottom: 40px;

			@media (max-width: $desktopSmall - 1) {
				flex-direction: column;
				flex-wrap: wrap;
				margin-bottom: 0;
				position: absolute;
				top: 100%;
				right: 0;
				width: 100%;
				background-color: #fff;
				transform-origin: 0 0 0;
				opacity: 0;
				visibility: hidden;
				transform: scaleY(0);

				&.open {
					opacity: 1;
					visibility: visible;
					transform: scaleY(1);
				}
			}

			> li {
				position: relative;
				display: flex;
				font-size: 15px;
				margin-left: 20px;
				align-items: center;
				color: $color_black;

				@media (max-width: $desktopSmall - 1) {
					margin-left: 0;
					flex-wrap: wrap;
					width: 100%;
				}

				> a {
					font-family: $Montserrat;
					font-weight: 500;
					display: flex;
					color: $color_black;
					align-items: center;
					text-transform: uppercase;
					letter-spacing: 0.35px;
					position: relative;
					padding: 10px 0;
					font-size: 14px;
					line-height: 1.2;

					@media (max-width: $desktopSmall - 1) {
						padding: 15px;
						width: 100%;
					}

					img {
						margin-left: 10px;
					}

					i {
						margin: 0 5px;
					}

					&:hover {
						color: $primary_color;
					}
				}

				&:first-child {

					a {
					
						&:after {
							content: "";
							position: absolute;
							top: 100%;
							left: 50%;
							transform: translateX(-50%);
							width: 35px;
							height: 1px;
							background-color: $color_black;
							transition: all .25s cubic-bezier(.61, .03, .43, .9);

							@media (max-width: $desktopSmall - 1) {
								top: 50%;
								left: auto;
								right: 15px;
								transform: translateY(-50%) rotate(-45deg);
								width: 7px;
								height: 7px;
								background-color: transparent;
								border-bottom: 1px solid $secondary_color;
								border-right: 1px solid $secondary_color;
							}
						}

						&:hover {

							&:after {
								background-color: $primary_color;
							}
						}
					}
				}

				& {

					a {

						&:after {
							content: "";
							position: absolute;
							transition: all .25s cubic-bezier(.61, .03, .43, .9);

							@media (max-width: $desktopSmall - 1) {
								top: 50%;
								left: auto;
								right: 15px;
								transform: translateY(-50%) rotate(-45deg);
								width: 7px;
								height: 7px;
								background-color: transparent;
								border-bottom: 1px solid $secondary_color;
								border-right: 1px solid $secondary_color;
							}
						}
					}
				}

				&.searchHandle {
					cursor: pointer;

					i {
						color: $primary_color;

						@media (max-width: $desktopSmall - 1) {
							display: none;
						}
					}

					span {
						font-size: 0;

						@media (max-width: $desktopSmall - 1) {
							font-family: $Montserrat;
							font-weight: 500;
							display: flex;
							color: $color_black;
							align-items: center;
							text-transform: uppercase;
							letter-spacing: 0.35px;
							position: relative;
							padding: 15px;
							font-size: 14px;
							line-height: 1.2;
							width: 100%;
						}

						&:after {
							content: "";
							position: absolute;
							transition: all .25s cubic-bezier(.61, .03, .43, .9);

							@media (max-width: $desktopSmall - 1) {
								top: 50%;
								left: auto;
								right: 15px;
								transform: translateY(-50%) rotate(-45deg);
								width: 7px;
								height: 7px;
								background-color: transparent;
								border-bottom: 1px solid $secondary_color;
								border-right: 1px solid $secondary_color;
							}
						}
					}

					&:hover {

						img {
							transform: scale(1.08);
						}
					}
				}
			}
		}
	}

	&.sticky {
		// background-color: rgba(0, 0, 0, 0.7);
		// box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);

		// #{$self}__logo {
		// 	width: 120px;
	
		// 	@media (max-width: $desktop_xl) {
		// 		width: 80px;
		// 	}
			
		// 	@media (max-width: $mobileBig) {
		// 		width: 80px;
		// 	}

		// 	a {
		// 		box-shadow: 0 5px 10px 3px rgba(black, 0.11);
		// 	}
		// }

		// #{$self}__right {

		// 	.headerLinks {

		// 		>li {
		// 			color: $secondary_color;

		// 			> a {
		// 				color: $secondary_color;

		// 				&:hover {
		// 					color: $primary_color;
		// 				}
		// 			}

		// 			&:first-child {

		// 				a {

		// 					&:after {
		// 						background-color: $secondary_color;
		// 					}

		// 					&:hover {

		// 						&:after {
		// 							background-color: $primary_color;
		// 						}
		// 					}
		// 				}
		// 			}

		// 			&.searchHandle {

		// 				i {
		// 					color: $secondary_color;

		// 					&:hover {
		// 						color: $primary_color;
		// 					}
		// 				}
		// 			}
		// 		}
		// 	}
		// }

		.searchBar {

			.autoSuggestion {
				top: 66px;
			}
		}
	}
	
	.searchBox {
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		width: 100%;
		padding: 48.5px 0;
		background: #fff;
		border-top: 1px solid #ddd;
		box-shadow: 0 10px 10px 2px rgba(68, 102, 161, 0.22);
		opacity: 0;
		visibility: hidden;
		transition: all 0.4s ease-out;

		form {
			width: calc(100% - 40px);
			display: flex;
			align-items: center;
			justify-content: space-between;
			flex-direction: column;

			.form-group {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 100%;
				margin: 0;
			}
		}

		&__input {
			width: 100%;
			outline: none;
			border: none;
			flex-grow: 1;
			padding: 0;
			font-size: 18px;
			font-family: $Montserrat;
			font-style: italic;
			background: none;
			// border-bottom: 1px solid rgba($secondary_color, 0.5);
			line-height: 1;
			height: 50px;

			@media (max-width: $desktopSmall - 1) {
				width: 100%;
			}
		}

		// &__button {
		// 	width: 140px;
		// 	flex-basis: 140px;
		// 	font-size: 20px;
		// 	flex-shrink: 1;
		// 	padding: 8px 0 8px 16px;
		// 	border: none;
		// 	outline: none;
		// 	background: url(../img/magnifier.svg) 14px center no-repeat;
		// 	background-size: 16px;
		// 	background-color: $primary_color;
		// 	border-radius: 5px;
		// 	color: #fff;
		// 	margin-left: 20px;

		// 	&:hover {
		// 		background-color: $primary_hover;
		// 		box-shadow: 0 2px 5px 0 rgba(68, 102, 161, 0.1);
		// 	}

		// 	@media (max-width: $mobileBig) {
		// 		width: 100%;
		// 		padding: 10px;
		// 		flex-basis: auto;
		// 	}
		// }

		&__close {
			width: 20px;

			img {
				width: 100%;
				float: left;
			}
		}
		
		&.show {
			opacity: 1;
			z-index: 10;
			visibility: visible;
		}

		@media (max-width: $desktopSmall) {
			padding: 45px 0;
			
			&__button {
				right: 15px;
			}
		}

		@media (max-width: $mobileBig) {
			padding: 13.5px 0;
		}
	}
}