.errorPages {
	padding: 150px 0 250px;
	background-position: center bottom;
	background-repeat: no-repeat;

	h1 {
		font-size: 150px;
		line-height: 150px;
		color: $primary_color;

		@media (max-width: $desktop) {
			font-size: 120px;
			line-height: 120px;
		}
		@media (max-width: $tablet) {
			font-size: 90px;
			line-height: 90px;
		}
		@media (max-width: $tabletSmall) {
			font-size: 70px;
			line-height: 70px;
		}
		@media (max-width: $mobileBig) {
			font-size: 60px;
			line-height: 60px;
		}
		@media (max-width: $mobile) {
			font-size: 45px;
			line-height: 45px;
		}
	}

	&.page_404 {
		background-image: image('img_404.jpg');
	}
	&.page_500 {
		background-image: image('img_500.jpg');
	}

	@media (max-width: $tablet) {
		padding: 100px 0 200px;
		background-size: 100%;
	}
	@media (max-width: $tabletSmall) {
		padding: 50px 0 180px;
	}
	@media (max-width: $mobile) {
		padding: 50px 0 100px;
	}
}
