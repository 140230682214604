.footer {
    position: relative;
    z-index: 1;
    padding: 0;
    background: $secondary_color;

    &__top {

        &-customGrid {
            display: flex;
            padding: 60px 0 80px;
            align-items: center;
            justify-content: space-between;

            &--item {
                width: 25%;

                &:nth-child(2) {
                    width: 36%;
                }

                h2 {
                    font-family: $Montserrat;
                    font-weight: 600;
                    font-size: 38px;
                    line-height: 1.2;
                    letter-spacing: 0.2px;
                    color: #fff;
                    margin: 0 0 50px;

                    @media (max-width: $desktop - 1) {
                        font-size: 32px;
                    }

                    @media (max-width: $mobileBig) {
                        font-size: 25px;
                        margin: 0 0 30px;
                    }
                }

                h3 {
                    font-family: $gv_normal;
                    font-weight: 400;
                    font-size: 45px;
                    line-height: 1.2;
                    color: #fff;
                    margin: 0 0 15px;

                    @media (max-width: $mobileBig) {
                        font-size: 35px;
                    }
                }

                h4 {
                    font-family: $Montserrat;
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 1.2;
                    letter-spacing: 0.5px;
                    color: #fff;
                    text-transform: uppercase;
                    margin: 0 0 10px;

                    @media (max-width: $mobileBig) {
                        font-size: 20px;
                    }
                }

                p {
                    font-family: $cr_medium;
                    font-size: 20px;
                    line-height: 1.5;
                    letter-spacing: 0.15px;
                    color: #fff;
                    margin: 0;
                    min-height: 60px;

                    a {
                        color: $color_white;
                        text-decoration: none;
                    }
                }
            }

            @media (max-width: $desktop - 1) {
                padding: 30px 0;

                &--item {
                    width: 26%;

                    &:nth-child(2) {
                        width: 48%;
                    }
                }
            }

            @media (max-width: $desktopSmall - 1) {
                padding: 30px 0;

                &--item {
                    width: 26%;

                    &:nth-child(2) {
                        width: 48%;
                    }
                }
            }

            @media (max-width: $mobileBig) {
                padding: 0;
                flex-direction: column;

                &--item {
                    width: 100% !important;
                    padding: 30px 0 0;
                }
            }
        }
    }

    &__socialLinks {
        position: relative;
        z-index: 1;

        ul {
            margin: 0;
            padding: 0;

            li {
                display: inline-block;
                
                &:not(:last-child) {
                    margin-right: 25px;
                }

                a {
                    display: flex;
                    color: white;
                    width: 33px;
                    height: 33px;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    z-index: 0;
                    font-size: 16px;

                    &:after {
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        border: 1px solid rgba(#ffffff, 0.57);
                        z-index: -1;
                        transform: rotate(45deg);
                    }

                    &:hover {

                        &:after {
                            background-color: $primary_color;
                        }
                    }
                }
            }
        }
    }

    &__bottom {
        border-top: 1px solid rgba(#ffffff, 0.1);

        &-copyrights {
            font-family: $Montserrat;
            text-align: center;
            font-size: 13px;
            line-height: 1.2;
            letter-spacing: 0.5px;
            color: rgba(#fff, 0.4);
            padding: 20px 0 30px;
            margin: 0;
        }
    }
    
    @media (max-width: $desktopSmall) {
        padding: 0;
    }

    @media (max-width: $tablet2) {
        text-align: center;
    }
}

.backtoTop {
	display:none;
	position:fixed;
	right:30px;
	bottom:60px;
	width:40px;
	height:40px;
    z-index:999;
    z-index: 999;
	cursor:pointer;
	background: $primary_color;
	
	&:before {
		content:'';
		position:absolute;
		top:0;
		left:0;
		width:40px;
		height:40px;
		z-index:-1;
		// background:rgba(0,0,0,0.2);
		@include css3Transition(400ms);
    }
    
	&:after {
		content:'';
		position:absolute;
		top:0;
		left:0;
		width:40px;
		height:40px;
		z-index:-2;
		// background:rgba(0,0,0,0.2);
		@include css3Transition(400ms);
	}
	
	&:hover {
		&:before {
			background:rgba( $color: $primary_color, $alpha: 0.2);
		}
		&:after {
			opacity:0;
			background:rgba( $color: $primary_color, $alpha: 0.2);
			@include css3Transform(scale(1.5));
		}
    }
    
    img {
        float: left;
    }

	@media(max-width:$tablet2) {
		right: 10px;
		bottom: 100px;
    }
}