.mainBanner {

    & {
        overflow: hidden;
        padding: 0;

        @media (max-width: $desktopSmall - 1) {
            margin-top: 78.09px;
        }

        .bannerSlider {
            padding: 0;

            .bannerSlider__box {
                position: relative;

                &-img {

                    @media (max-width: $desktop - 1) {
                        min-height: calc(600px - 78.09px);
                    }

                    @media (max-width: $mobileBig) {
                        min-height: calc(568px - 78.09px);
                    }

                    img {
                        width: 100%;
                        animation: zoomEffect 180s linear 2s infinite alternate;

                        @keyframes zoomEffect {
                            0% {
                                transform: scale(1.0);
                            }

                            50% {
                                transform: scale(1.5);
                            }

                            100% {
                                transform: scale(1.0);
                            }
                        }

                        @media (max-width: $desktop - 1) {
                            width: 100%;
                            height: auto;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            animation: none;
                        }

                        @media (max-width: $desktopSmall - 1) {
                            width: auto;
                            max-width: none;
                            height: 100%;
                            position: absolute;
                            top: 0;
                            left: auto;
                            right: 0;
                            transform: translate(0%, 0%);
                            animation: none;
                        }
                    }
                }

                &-content {
                    position: absolute;
                    top: 50%;
                    right: 10%;
                    transform: translateY(-50%);
                    width: 475px;
                    text-align: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;

                    @media (max-width: $desktop_xl) {
                        right: 3%;
                        top: calc(50% - 20px);
                    }

                    @media (max-width: $desktop - 1) {
                        right: 4%;
                        width: 400px;
                    }

                    @media (max-width: $mobileBig) {
                        top: 50%;
                        right: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        padding: 0 15px;
                        border-radius: 0;
                    }

                    h3 {
                        font-size: 60px;
                        font-family: $gv_normal;
                        font-weight: 400;
                        line-height: 1;
                        color: $primary_color;
                        margin: 0;

                        @media (max-width: $desktop - 1) {
                            font-size: 50px;
                        }

                        // @media (max-width: $desktop_xl) {
                        //     font-size: 25px;
                        // }
                    }

                    h2 {
                        font-size: 80px;
                        font-family: $Montserrat;
                        font-weight: 600;
                        line-height: 1;
                        color: $secondary_color;
                        margin: 0;
                        letter-spacing: 8px;
                        text-transform: uppercase;

                        @media (max-width: $desktop - 1) {
                            font-size: 65px;
                        }

                        @media (max-width: $mobileBig) {
                            font-size: 50px;
                        }
                    }

                    span {
                        margin: 25px 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        @media (max-width: $desktop_xl) {
                            margin: 20px 0;
                        }

                        img {
                            width: auto;
                        }
                    }

                    p {
                        font-size: 22px;
                        font-family: $cr_italic;
                        font-weight: 400;
                        line-height: 1.5;
                        color: $secondary_color;
                        margin: 0;

                        // @media (max-width: $desktop_xxl) {
                        //     font-size: 22px;
                        //     line-height: 1.2;
                        // }

                        // @media (max-width: $desktop_xl) {
                        //     font-size: 20px;
                        // }

                        @media (max-width: $mobileBig) {
                            font-size: 18px;
                        }
                    }
                }
            }
        }
    }
}

.wholeSales-section {
    background: url(../img/wholesales/wholesale-bg1.png) no-repeat left bottom 30px,
                transparent url(../img/wholesales/wholesale-bg2.png) no-repeat right top 60px;

    @media (max-width: $desktopSmall - 1) {
        background: none;
    }

    .wholeSales {
        text-align: center;

        @media (max-width: $desktop - 1) {
            padding: 0 90px;
        }

        @media (max-width: $desktopSmall - 1) {
            padding: 0;
        }

        h1 {
            font-size: 75px;
            font-family: $gv_normal;
            font-weight: 400;
            line-height: 1;
            color: $primary_color;
            letter-spacing: -0.05px;
            margin: 0 0 15px;

            @media (max-width: $mobileBig) {
                font-size: 50px;
            }
        }

        h3 {
            font-size: 25px;
            font-family: $Montserrat;
            font-weight: 500;
            line-height: 1.2;
            color: $secondary_color;
            margin: 0;

            @media (max-width: $mobileBig) {
                font-size: 20px;
            }
        }

        span {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 20px 0;

            img {
                width: auto;
            }
        }

        p {
            font-size: 22px;
            font-family: $cr_italic;
            font-weight: 400;
            line-height: 1.4;
            color: $secondary_color;
            margin: 0 0 20px;

            @media (max-width: $mobileBig) {
                font-size: 20px;
            }

            a {
                font-family: $cr_semiitalic;

                &:hover {
                    color: $secondary_color;
                }
            }
        }
    }
}

.ingredients-section {
    background: transparent url(../img/ingredients/ingredients-bg.jpg) no-repeat fixed 50% center / cover;

    .ingredients {
        text-align: center;

        h2 {
            font-size: 78px;
            font-family: $gv_normal;
            font-weight: 400;
            line-height: 1.2;
            color: white;
            margin: 0 0 15px;

            @media (max-width: $mobileBig) {
                font-size: 50px;
            }
        }
        
        p {
            font-size: 24px;
            font-family: $Montserrat;
            font-weight: 300;
            line-height: 1.5;
            color: white;
            margin: 0;

            @media (max-width: $mobileBig) {
                font-size: 18px;
            }

            strong {
                font-family: $Montserrat;
                font-weight: 700;
            }
        }
    }
}

.ourFlavours-section {

    .ourFlavours {
        text-align: center;

        &__heading {

            h1 {
                font-size: 62px;
                font-family: $gv_normal;
                font-weight: 400;
                line-height: 1;
                color: $primary_color;
                letter-spacing: -0.05px;
                margin: 0 0 15px;

                @media (max-width: $mobileBig) {
                    font-size: 50px;
                }
            }

            span {
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 40px 0;

                img {
                    width: auto;
                }
            }
        }

        &__content {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;

            &-box {
                width: calc(100% / 5);
                margin: 0 0 50px;

                @media (max-width: $desktop - 1) {
                    width: calc(100% / 4);
                }

                @media (max-width: $desktopSmall - 1) {
                    width: calc(100% / 3);
                }
		
                @media (max-width: $mobileBig) {
                    width: calc(100% / 2);
                }

                @media (max-width: $mobile - 1) {
                    width: calc(100% / 1);
                }

                figure {
                    margin: 0 auto 2px;
                    width: 191px;
                    height: 191px;
                    background-color: #fff;
                    position: relative;
                    overflow: hidden;

                    &:after {
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background-color: rgba($color_white, 0.9);
                        opacity: 0;
                        visibility: hidden;
                        transition: all 0.5s ease;
                    }

                    img {
                        width: auto;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }

                    a {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        position: absolute;
                        top: 50%;
                        left: 0;
                        transform: translateY(-50%);
                        width: 100%;
                        height: 100%;
                        z-index: 5;
                        opacity: 0;
                        visibility: hidden;
                        font-family: $Montserrat;
                        font-size: 22px;
                        line-height: 1.2;
                        letter-spacing: 0.15px;
                        color: $primary_color;
                        transition: all 0.5s ease;
                    }
                }

                h4 {
                    font-family: $gv_normal;
                    font-weight: 400;
                    font-size: 24px;
                    line-height: 1.2;
                    letter-spacing: 0.15px;
                    color: $secondary_color;
                    margin: 0;
                }

                // &:hover {

                //     figure {

                //         &:after {
                //             opacity: 1;
                //             visibility: visible;
                //         }
    
                //         a {
                //             opacity: 1;
                //             visibility: visible;
                //         }
                //     }
                // }
            }
        }
    }
}

.productImages-section {
    padding: 6px 0 0;

    .productImages {
        display: flex;
        flex-wrap: wrap;

        &__box {
            margin: 0 6px 6px 0;
            overflow: hidden;

            &:nth-child(3),
            &:nth-child(5) {
                margin-right: 0;
            }

            &:nth-child(4),
            &:nth-child(5) {
                margin-bottom: 0;
            }

            &.first {
                width: calc(25.75% - 6px);
		
                @media (max-width: $mobileBig) {
                    width: 100%;
                }
            }

            &.second {
                width: calc(26.25% - 6px);
		
                @media (max-width: $mobileBig) {
                    width: 100%;
                }
            }

            &.third {
                width: calc(48% - 0px);
		
                @media (max-width: $mobileBig) {
                    width: 100%;
                }
            }

            &.fourth {
                width: calc(57.25% - 6px);
		
                @media (max-width: $mobileBig) {
                    width: 100%;
                }
            }

            &.fifth {
                width: calc(42.75% - 0px);
		
                @media (max-width: $mobileBig) {
                    width: 100%;
                }
            }

            img {
                width: 100%;
                transition: all 0.5s ease;
            }
		
            @media (max-width: $mobileBig) {
                margin: 0 0 6px !important;
            }

            &:hover {
                
                img {
                    transform: scale(1.2);
                }
            }
        }
    }
}

.contactUs-section {
    position: relative;
    z-index: 0;

    &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 30%;
        background-color: #f7f7f7;
        z-index: -1;

        @media (max-width: $desktop - 1) {
            height: 35%;
        }
    }

    .contactUs {

        &__heading {
            text-align: center;
            margin-bottom: 60px;

            h2 {
                font-size: 75px;
                font-family: $gv_normal;
                font-weight: 400;
                line-height: 1;
                color: $primary_color;
                letter-spacing: 0.5px;
                margin: 0;

                @media (max-width: $mobileBig) {
                    font-size: 50px;
                }
            }

            h4 {
                font-size: 25px;
                font-family: $Montserrat;
                font-weight: 500;
                line-height: 1.2;
                color: $secondary_color;
                margin: 0;

                @media (max-width: $mobileBig) {
                    font-size: 20px;
                }
            }

            span {
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 20px 0;

                img {
                    width: auto;
                }
            }
        }

        &__form {

            h3 {
                font-family: $Montserrat;
                font-weight: 600;
                font-size: 35px;
                line-height: 1;
                color: $secondary_color;
                margin: 0 0 40px;
                text-align: center;

                @media (max-width: $mobileBig) {
                    font-size: 30px;
                }
            }

            form {

                .padding-left {
                    padding-left: 8px;
		
                    @media (max-width: $mobileBig) {
                        padding-left: 15px;
                    }
                }

                .padding-right {
                    padding-right: 8px;
		
                    @media (max-width: $mobileBig) {
                        padding-right: 15px;
                    }
                }

                .form-group {
                    margin: 0 0 16px;

                    input,
                    textarea {
                        outline: none;
                        border-radius: 0;
                        border: 1px solid #dddddd;
                        padding: 13px 15px;
                        background-color: #fff;
                        font-family: $Montserrat;
                        font-weight: 300;
                        font-size: 13px;
                        line-height: 1.2;
                        letter-spacing: 0.5px;
                        color: #4f4f4f;

                        &:focus {
                            border-color: $primary_color;
                        }

                        &::-webkit-input-placeholder {
                            font-family: $Montserrat;
                            font-weight: 300;
                            font-size: 13px;
                            line-height: 1.2;
                            letter-spacing: 0.5px;
                            color: #4f4f4f;
                        }

                        &::-moz-placeholder {
                            font-family: $Montserrat;
                            font-weight: 300;
                            font-size: 13px;
                            line-height: 1.2;
                            letter-spacing: 0.5px;
                            color: #4f4f4f;
                        }

                        &:-ms-input-placeholder {
                            font-family: $Montserrat;
                            font-weight: 300;
                            font-size: 13px;
                            line-height: 1.2;
                            letter-spacing: 0.5px;
                            color: #4f4f4f;
                        }

                        &:-moz-placeholder {
                            font-family: $Montserrat;
                            font-weight: 300;
                            font-size: 13px;
                            line-height: 1.2;
                            letter-spacing: 0.5px;
                            color: #4f4f4f;
                        }
                    }
                }

                .button-section {
                    position: relative;
                    z-index: 0;
                    text-align: center;

                    .btn-custom {
                        padding: 20px 65px;
                        position: relative;
                        text-transform: uppercase;
                        font-family: $Montserrat;
                        font-weight: 300;

                        &:after {
                            content: "";
                            position: absolute;
                            top: 6px;
                            left: 6px;
                            width: calc(100% - 12px);
                            height: calc(100% - 12px);
                            border: 1px solid rgba(#ffffff, 0.57)
                        }
                    }

                    input.btn-custom {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        opacity: 0;
                        z-index: 2;

                        &:hover {

                            + button.btn-custom {

                                &:before {
                                    height: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}